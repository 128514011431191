import React from 'react';
import { Intro } from './Intro';
import { ImageCard, ProgressBarCard, PromptCard, root } from '.';


export class EndGame extends React.Component {
    render() {
        return (
            <div>
                <button class="return-button" onClick={() => { root.render(<Intro/>); }}>Return to Map</button>
                <h1 class="blue">EndGame</h1>
                <h2 class="red">score {this.props.score}</h2>
            </div>
        );
    }
}
