import React from 'react';
import { ImageCard, ProgressBarCard, PromptCard, root } from '.';
import { StrictMode } from 'react';
import { EndGame } from './EndGame';
import { Intro } from './Intro';
import './game.css';

export class Field extends React.Component {
    progress = 0;
    score = 0; 
    health = 5;
    hearts = new Array(5).fill('❤️')

    constructor(props) {
        super(props);
        this.state = {
            imgurl: '',
            wished_img: '',
            wished_prompt: '',
            images: new Array(10).fill(''),
            prompts: new Array(4).fill(''),
            progress: this.progress,
        };
        
    }
    componentDidMount () {
        fetch('https://app.romecraft.ru/api/get/config')
            .then(response => response.json())
            .then(data => this.setState(data));
        // this.getImage();
        this.getImages();
    }
    componentDidUpdate () {
        if (this.progress >= 10 || this.progress - this.score >= 5) {
            root.render(<EndGame score={this.score}/>);
        }
    }
    render() {
        const imgurl = this.state['imgurl'];
        let url = `${imgurl}${this.state.images[this.progress]}.jpg`;
        let progress = {
            "width": this.progress *10 +"%"
        }
        //💔
        for (let i = 4; i>= 5- (this.progress - this.score); i--) {
            this.hearts[i] = '💔'
            console.log(`progress ${this.progress} ${this.score}`)
        }
               
        return (
            <div class="container">
                <div class="level">Level {this.props.dataset}</div>
                <button class="return-button" onClick={() => { root.render(<StrictMode><Intro/></StrictMode>); }}>Return to Map</button>
                <div class="game-container">
                    <div class="left-column">
                        <div class="progress-bar">
                            <div class="progress-bar-fill" style={progress}></div>
                        </div>
                        <img class="player-image" src={url}  alt="Player Image"/>
                    </div>
                    <div class="right-column">
                        <div class="health-bar">
                            <span class="heart">{this.hearts[0]}</span>
                            <span class="heart">{this.hearts[1]}</span>
                            <span class="heart">{this.hearts[2]}</span>
                            <span class="heart">{this.hearts[3]}</span>
                            <span class="heart">{this.hearts[4]}</span>
                        </div>
                        <div class="answer-buttons">
                            <button class="answer-button" onClick={() => { this.validateAnswer(this.state.prompts[0])}}>{this.state.prompts[0]}</button>
                            <button class="answer-button" onClick={() => { this.validateAnswer(this.state.prompts[1])}}>{this.state.prompts[1]}</button>
                            <button class="answer-button" onClick={() => { this.validateAnswer(this.state.prompts[2])}}>{this.state.prompts[2]}</button>
                            <button class="answer-button" onClick={() => { this.validateAnswer(this.state.prompts[3])}}>{this.state.prompts[3]}</button>
                        </div>
                    </div>
                </div>
            </div>
            // <div class="playfield">
                
            //     <ImageCard link={url} />
                
            //         <ProgressBarCard value={this.progress} max={9} />
            //         <h1 class="blue">Правильно {this.score}/{this.progress}</h1>
            //         <div class="button-row">
            //             <PromptCard value={this.state.prompts[0]} class="red" onClick={() => { this.validateAnswer(this.state.prompts[0])}} />
            //             <PromptCard value={this.state.prompts[1]} class="green" onClick={() => {this.validateAnswer(this.state.prompts[1])}} />
            //         </div>
            //         <div class="button-row">
            //             <PromptCard value={this.state.prompts[2]} class="blue" onClick={() => {this.validateAnswer(this.state.prompts[2])}} />
            //             <PromptCard value={this.state.prompts[3]} class="yellow" onClick={() => {this.validateAnswer(this.state.prompts[3])}} />
            //         </div>
                
            // </div>
        );
    }
    getImage() {
        // this.setState({ progress: this.state.progress + 1});
        fetch('https://app.romecraft.ru/api/get/getImage')
            .then(response => response.json())
            .then(data => this.setState({
                wished_img: data[0][1],
                wished_prompt: data[0][2]
            }, () => {
                this.getPrompts();
            }));
    }

    getImages() {
        // this.setState({ progress: this.state.progress + 1});
        fetch(`https://app.romecraft.ru/api/get/getImages/${this.props.dataset}`)
            .then(response => response.json())
            .then((data) =>{ this.updateImages(data)}); 
    }
    updateImages(data) {
        let buff = [];
        if (data) {
            data.forEach(element => {
                buff.push(element[1]);
            });
            this.setState({ 'images': buff },() => {this.getPrompts()});
        }
    }
    handleSuggestion() {
        // console.log(this.progress)
        // this.validateAnswer(prompt);
        this.progress = this.progress + 1;
        
        this.setState({ progress: this.progress});
        this.getPrompts();
    }

    getPrompts() {
        if (this.state.images.length > this.progress) {
            let payload = {
                "img": this.state.images[this.progress]
            };
            fetch(`https://app.romecraft.ru/api/get/getPrompts/${this.props.dataset}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(data => this.updatePrompts(data));
        }
    }
    updatePrompts(data) {
        console.log(data)
        let buff = [];
        if (data) {
            data.forEach(element => {
                buff.push(element[2]);
            });
            this.setState({ prompts: buff });
        }
    }
    validateAnswer(prompt){
        let payload = {
            "img": this.state.images[this.progress],
            "prompt": prompt,
            "dataset": this.props.dataset,
        }
        
        fetch(`https://app.romecraft.ru/api/get/verify/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then((data) => {if(data['result']) (this.score = this.score + 1) })
        .then(() => {this.handleSuggestion()});

    }
}
