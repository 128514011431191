import React from 'react';
// import Image from 'next/image';
import ReactDOM from 'react-dom/client';
import ProgressBar from "@ramonak/react-progress-bar";

import { StrictMode } from 'react';
import { Intro } from './Intro';

export const root = ReactDOM.createRoot(document.getElementById("root"));
export class ImageCard extends React.Component{
    render() {
        return (<img
        
        class="img"
        alt="загаданное изображение"
        src= {this.props.link}/>)
    }
}

export class PromptCard extends React.Component{
    render() {
        return (
        <button class={`btn ${this.props.class}`} onClick={() => this.props.onClick()}>{this.props.value}</button>
        )
    }
}

export class ProgressBarCard extends React.Component{
    render() {
        return (<div>
                <ProgressBar 
                    completed={this.props.value}
                    isLabelVisible={false}
                    animateOnRender
                    maxCompleted={this.props.max}
                    width="400"
                    />
            </div>
        )
    }
}

root.render(<StrictMode><Intro /></StrictMode>);