import React from 'react';
import { Field } from './Field';
import { StrictMode } from 'react';
import { root } from '.';
import './intro.css';



export class Intro extends React.Component {
    toggleNav() {
        document.querySelector('nav').classList.toggle('show');
    }
    render() {
        return (
            <div class='introBody' style={{"background-color": "#f0f0f0"}}>
    <header>
        <h1>Game Menu</h1>
        <div class="menu">
            <div class="burger-menu" onClick={() => { this.toggleNav()}}>
                <span>&#9776;</span>
            </div>
            <nav>
                <a href="#">Home</a>
                <a href="#">Levels</a>
                <a href="#">Rating</a>
                <a href="#">Exit</a>
            </nav>
        </div>
    </header>

    <main>
        <div class="scroll-container">
            <div class="level-selector">
                {/* <!-- Example level cards --> */}
                <div class="level-card" onClick={() => { root.render(<StrictMode><Field dataset='item'/></StrictMode>); }} >item</div>
                <div class="level-card" onClick={() => { root.render(<StrictMode><Field dataset='abstract'/></StrictMode>); }}>abstract</div>
                <div class="level-card" onClick={() => { root.render(<StrictMode><Field dataset='condition'/></StrictMode>); }}>condition</div>
                <div class="level-card">Level 4</div>
                <div class="level-card">Level 5</div>
                <div class="level-card">Level 6</div>
                <div class="level-card">Level 7</div>
                <div class="level-card">Level 8</div>
                <div class="level-card" disabled>Level 9</div>
                {/* <!-- Add more level cards as needed --> */}
            </div>
        </div>
    </main>

    <footer>
        <div class="scroll-container">
            <div class="friend-list">
                {/* <!-- Example friend cards with avatars --> */}
                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 1 Avatar"/>
                    </div>
                    <p>Friend 1<br/>Score: 100</p>
                </div>

                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 2 Avatar"/>
                    </div>
                    <p>Friend 2<br/>Score: 90</p>
                </div>
                {/* <!-- Add more friend cards as needed --> */}
                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 1 Avatar"/>
                    </div>
                    <p>Friend 3<br/>Score: 100</p>
                </div>

                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 2 Avatar"/>
                    </div>
                    <p>Friend 4<br/>Score: 90</p>
                </div>
                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 1 Avatar"/>
                    </div>
                    <p>Friend 5<br/>Score: 100</p>
                </div>

                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 2 Avatar"/>
                    </div>
                    <p>Friend 6<br/>Score: 90</p>
                </div>
                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 1 Avatar"/>
                    </div>
                    <p>Friend 7<br/>Score: 100</p>
                </div>

                <div class="friend-card">
                    <div class="avatar">
                        <img src="https://app.romecraft.ru/images/AgACAgIAAxkBAAIFumWN-x5ecQ8HgqM42T4P0jlVCV52AALw0jEbUsJxSFg3Qe9vBmAHAQADAgADeAADNAQ.jpg" alt="Friend 2 Avatar"/>
                    </div>
                    <p>Friend 8<br/>Score: 90</p>
                </div>
            </div>
        </div>
    </footer>
            {/* <div class="intro">
                        <a onClick={() => { root.render(<StrictMode><Field dataset='item'/></StrictMode>); }} alt="Mythrill" target="_blank">
                            item
                        </a>
                        <br></br>
                        <a onClick={() => { root.render(<StrictMode><Field dataset='abstract'/></StrictMode>); }} alt="Mythrill" target="_blank">
                            abtract
                        </a>
                        <br></br>
                        <a onClick={() => { root.render(<StrictMode><Field dataset='condition'/></StrictMode>); }} alt="Mythrill" target="_blank">
                            condition   
                        </a>
                        
                    </div> */}
            </div>
            
        );
    }
}
